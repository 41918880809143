<template>
  <div v-if="form.orders != ''">
    <b-row>
      <!--begin::Stats Widget 11-->
      <div class="card card-custom gutter-b ml-5">
        <!--begin::Body-->
        <div class="card-body p-0">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              card-spacer
              flex-grow-1
            "
          >
            <div class="d-flex flex-column text-center">
              <span class="text-dark-75 font-weight-bolder font-size-h3">{{
                totalPending
              }}</span>
              <span class="text-muted font-weight-bold mt-2"
                >Pending Orders</span
              >
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stats Widget 11-->
    </b-row>
    <b-row>
      <b-col>
        <OrdersTableWidget
          :orders="form.orders"
          :title="'Outstanding Orders'"
          @update="fetchOrders"
        ></OrdersTableWidget>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <center><h2 class="font-poppins">No Outstanding Orders</h2></center>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TotalOrdersWidget from "@/view/pages/orders/components/TotalOrdersWidget.vue";
import AcceptedOrdersWidget from "@/view/pages/orders/components/AcceptedOrdersWidget.vue";
import CancelledOrdersWidget from "@/view/pages/orders/components/CancelledOrdersWidget.vue";
import OrdersTableWidget from "@/view/pages/orders/components/OrdersTableWidget.vue";
import { ORDERS } from "@/core/services/store/sales.module";
import { mapGetters } from "vuex";

export default {
  name: "TodayOrders",
  computed: {
    ...mapGetters(["currentUser", "acknowledged", "getDate"]),
    totalPending() {
      return this.form.orders.length;
    },
  },
  components: {
    TotalOrdersWidget,
    AcceptedOrdersWidget,
    CancelledOrdersWidget,
    OrdersTableWidget,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders" },
      { title: "Outstanding Orders" },
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      this.fetchOrders();
    }
  },

  data() {
    return {
      form: {
        orders: "",
      },
    };
  },
  methods: {
    fetchOrders() {
      this.$store
        .dispatch(ORDERS, {
          ready: 1,
        })
        // go to which page after successfully login
        .then((resp) => {
          this.form.orders = resp.data;
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
